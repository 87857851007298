<template>
  <div
    v-if="sweepstakesDisplay.length"
    class="mb-3"
  >
    <h2
      v-if="title && categoryName !== 'special-feature'"
      class="block font-bold tracking-wider text-lg lg:text-xl leading-none category-title"
    >
      {{ title }}
    </h2>

    <ListSweepstakeExpander
      :key="sweepstakesDisplay[0]?.id ? `${sweepstakesDisplay.length}-${sweepstakesDisplay[0]?.id}` : 'sweepstake-expander'"
      :sweepstakes-display="sweepstakesDisplaySorted"
      :exchange-rate-eth-usd="exchangeRateEthUsd"
      :hide-price="hidePrice"
      :carousel-initial-state="!!displayMode?.carouselInitialState"
      :card-size="cardSize"
      :wide="title === 'Crypto'"
      :show-rtp="showRtp"
      :show-live-rtp="showLiveRtp"
      :show-volatility="showVolatility"
      @card-action="cardAction"
    />
  </div>
</template>

<script>
export default defineComponent({
  name: 'ListSweepstakeCategory',
  inject: ['mq',],
  props: {
    sweepstakesDisplay: {
      type: Object,
      default: null,
    },
    exchangeRateEthUsd: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    categoryName: {
      type: String,
      default: null,
    },
    displayMode: {
      type: Object,
      default: null,
    },
    hidePrice: {
      type: Boolean,
      default: null,
    },
    cardSize: {
      type: String,
      default: null,
    },
    showRtp: {
      type: Boolean,
      default: false,
    },
    showLiveRtp: {
      type: Boolean,
      default: false,
    },
    showVolatility: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'card-action',
  ],
  computed: {
    sweepstakesDisplaySorted() {
      let sweepstakes = this.sweepstakesDisplay;

      if (!sweepstakes) {
        return [];
      }

      if (this.categoryName === 'head-to-head') {
        sweepstakes = sweepstakes.sort((a, b) => {
          return b.progress - a.progress;
        });
      }

      // If any of the items in sweepstakes have a property indexInCategory witha numeric value, move that item to that index in the array
      sweepstakes?.forEach((item, index) => {
        if (item.metadata?.indexInCategory !== undefined) {
          sweepstakes = this.$moveElementInArray(sweepstakes, index, item.metadata.indexInCategory);
        }
      });

      return sweepstakes;
    },
    screenSize() {
      return this.mq.current;
    },
  },
  methods: {
    cardAction(item) {
      this.$emit('card-action', item);
    },
  },
});
</script>

<style scoped lang="scss">
.category-title {
  max-width: calc(100% - 115px); /* full width minus the width of the grid and swipe buttons */
}
</style>
